import React, { useMemo } from 'react';
import { Alert, Media, Tooltip } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import dayjs from 'dayjs';
import Link from 'next/link';

import { NFTTooltip } from '@src/components/Staking/NFTTooltip';
import { CONTRACT } from '@src/config';
import { useAppSelector } from '@src/hooks';
import { StakingModalTabs } from '@src/ts/constants';
import { getPoolMultiplier, parseLockPeriod } from '@src/utils/staking';
import { parseBalance, removeCommas } from '@src/utils/web3';

import { InfoText } from '../..';

import { getWithdrawAmount } from './util';

export const TextItem: React.FC<{
    text: string | React.ReactChild;
    title: string;
    info?: string;
    checked?: boolean;
}> = ({ text, title, info, checked }) => {
    return (
        <div className="flex my-2">
            <div className="flex-1 text-secondary">
                {info ? <InfoText info={info} text={title} /> : title}
            </div>
            <div
                className={`flex items-center space-x-2 ${
                    checked ? 'text-success' : ''
                }`}
            >
                <strong>{text}</strong>
            </div>
        </div>
    );
};

export const deposit_warning_messages = {
    liquidity: (
        <div>
            <p>
                Make sure you understand the risk of impermanent loss by
                providing liquidity in this pool. It is the users sole
                responsibility to look after their investments.
            </p>
            <p className="mt-2">
                Learn more in{' '}
                <Link
                    href="https://coinmarketcap.com/alexandria/glossary/impermanent-loss"
                    className="hover:text-underline text-accent"
                    target="_blank"
                    rel="noopener,noreferrer"
                >
                    this article
                </Link>
            </p>
        </div>
    ),
    'legacy-liquidity': (
        <div>
            <p>
                Make sure you understand the risk of impermanent loss by
                providing liquidity in this pool. It is the users sole
                responsibility to look after their investments.
            </p>
            <p className="mt-2">
                Learn more in{' '}
                <Link
                    href="https://coinmarketcap.com/alexandria/glossary/impermanent-loss"
                    className="hover:text-underline text-accent"
                    target="_blank"
                    rel="noopener,noreferrer"
                >
                    this article
                </Link>
            </p>
        </div>
    ),
    v1: 'Depositing more into this pool before the lock end date will reset the rewards earned so far to 0',
};

export const withdraw_warning_messages = {
    liquidity:
        'Anyone can withdraw at any time. However, withdrawing before the lock end date will yield no rewards.',
    'legacy-liquidity':
        'Anyone can withdraw at any time. However, withdrawing before the lock end date will yield no rewards.',
    v1: 'In order to receive the rewards you must claim them before withdrawing from this pool.',
};

export const SummaryText: React.FC = () => {
    const { withdraw_percent } = useAppSelector((state) => state.staking);

    const {
        current_pool,
        pools,
        modal_state: current,
        deposit_amount,
        token1_deposit_amount = '0',
    } = useAppSelector((state) => state.staking);

    const pool = pools.find(({ id }) => id === current_pool);

    const {
        id,
        type,
        user_stake = ['0', '0'],
        earned_reward = '0',
        has_stake,
        apr = '0',
        input_token = [
            CONTRACT.BaseToken[pool.chain_id],
            CONTRACT.BaseToken[pool.chain_id],
        ],
        rebate_percent,
        early_withdraw_penality,
        last_user_deposit,
        is_early_withdraw_active,
        reward_token,
        is_unlocked,
        tier_boost,
    } = pool || {};

    const pool_is_expired = dayjs().isAfter(
        dayjs(pool?.end_date || new Date()),
    );

    const is_multi_asset = type === 'multi-asset';
    const is_legacy_compound = type === 'legacy-compound';
    const is_liquidity = type === 'liquidity';
    const is_legacy_pool_with_stake =
        !pool_is_expired && has_stake && type === 'v1';
    const display_withdraw_message =
        type === 'v1' || (type === 'liquidity' && is_unlocked);
    const is_rebate = rebate_percent > 0;

    const lock_period = parseLockPeriod(pool);

    const lock_end_date = dayjs(last_user_deposit || new Date())
        .add(parseInt(lock_period), 'days')
        .format('HH:mm / DD MMM, YYYY');

    const has_rebate = rebate_percent > 0;

    const withdraw_amount = getWithdrawAmount(
        BigNumber.from(user_stake[0]).add(
            is_multi_asset || is_liquidity ? 0 : earned_reward,
        ),
        is_liquidity ? withdraw_percent : 100,
    );
    const early_withdraw_fee_amount = is_rebate
        ? withdraw_amount.div(100).mul(early_withdraw_penality)
        : 0;

    const withdraw_amount_after_fee = withdraw_amount.sub(
        early_withdraw_fee_amount,
    );

    const next_balance = BigNumber.from(
        parseUnits(
            removeCommas(deposit_amount || '0'),
            input_token[0].decimals,
        ),
    ).add(user_stake[0]);

    const next_balance_token1 = BigNumber.from(
        parseUnits(
            removeCommas(token1_deposit_amount),
            input_token[1]?.decimals || 18,
        ),
    ).add(user_stake[1] || '0');

    const { boost, has_boost } = useMemo(() => getPoolMultiplier(pool), [pool]);

    return (
        <div>
            {current === StakingModalTabs.Deposit && (
                <div className="mt-5 mb-20">
                    {is_liquidity && <TextItem title="Reward" text={apr} />}

                    <div className="flex my-2">
                        <div className="flex-1 text-secondary">
                            <div className="flex items-center flex-wrap space-x-2">
                                <p>NFT boost</p>
                                <NFTTooltip id={id}>
                                    <Media
                                        className="text-accent"
                                        size={5}
                                        variant="info-outline"
                                    />
                                </NFTTooltip>
                            </div>
                        </div>
                        <div
                            className={`flex items-center space-x-2 ${
                                has_boost ? 'text-success' : ''
                            }`}
                        >
                            <strong>+{boost}%</strong>
                        </div>
                    </div>

                    <div className="flex my-2">
                        <div className="flex-1 text-secondary">
                            <div className="flex items-center flex-wrap space-x-2">
                                <p>Tier boost</p>
                                <Tooltip
                                    id={`id`}
                                    text={`<div><p>Stake in this pool to receive a <span class='text-white'><strong>+${
                                        tier_boost || '0'
                                    }%</strong></span> tier boost.</p></div>`}
                                >
                                    <Media
                                        className="text-accent"
                                        size={5}
                                        variant="info-outline"
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            className={`flex items-center space-x-2 ${
                                has_boost ? 'text-success' : ''
                            }`}
                        >
                            <strong>+{tier_boost}%</strong>
                        </div>
                    </div>

                    {has_stake && (
                        <TextItem
                            title="Stake after deposit"
                            text={
                                <div className="text-right">
                                    <p>
                                        {parseBalance(
                                            next_balance.add(
                                                !is_legacy_compound
                                                    ? 0
                                                    : earned_reward,
                                            ),
                                            input_token[0].decimals,
                                        )}{' '}
                                        {input_token[0].symbol}
                                    </p>
                                    {is_liquidity && (
                                        <p data-testid="token1-next-balance">
                                            {parseBalance(
                                                next_balance_token1,
                                                input_token[1]?.decimals,
                                            )}{' '}
                                            {input_token[1]?.symbol}
                                        </p>
                                    )}
                                </div>
                            }
                        />
                    )}

                    {lock_period !== '' && (
                        <TextItem
                            title={(has_stake ? 'New l' : 'L') + 'ock end date'}
                            text={dayjs()
                                .add(Number(lock_period), 'days')
                                .format('HH:mm MMM DD, YYYY')}
                            info="You can withdraw the deposited amount and rewards after the lock date has expired."
                        />
                    )}

                    {(is_liquidity || is_legacy_pool_with_stake) && (
                        <Alert
                            variant="note"
                            title="Important information"
                            className="mt-5"
                        >
                            {deposit_warning_messages[type]}
                        </Alert>
                    )}
                </div>
            )}{' '}
            {current === StakingModalTabs.Withdraw && (
                <div className="my-4">
                    <div className="flex my-2">
                        <div className="flex-1">
                            <p className="text-secondary">Withdraw amount</p>
                        </div>
                        <div className="text-right">
                            <p>
                                <strong>
                                    {parseBalance(
                                        getWithdrawAmount(
                                            BigNumber.from(user_stake[0]).add(
                                                is_multi_asset || is_liquidity
                                                    ? 0
                                                    : earned_reward,
                                            ),
                                            is_liquidity
                                                ? withdraw_percent
                                                : 100,
                                        ),
                                        input_token[0].decimals,
                                        is_liquidity ? 3 : 2,
                                    )}{' '}
                                    {input_token[0].symbol}
                                    {/* todo make ui for rebate early withdraw that includes the penalty */}
                                </strong>
                            </p>
                            {is_liquidity && (
                                <p data-testid="token1-withdraw-balance">
                                    <strong>
                                        {parseBalance(
                                            getWithdrawAmount(
                                                BigNumber.from(user_stake[1]),
                                                withdraw_percent,
                                            ),
                                            input_token[1]?.decimals,
                                            is_liquidity ? 3 : 2,
                                        )}{' '}
                                        {input_token[1]?.symbol}
                                    </strong>
                                </p>
                            )}
                        </div>
                    </div>
                    {is_multi_asset && (
                        <div
                            data-testid="multi-asset-div"
                            className="flex my-2"
                        >
                            <div className="flex-1">
                                <p className="text-secondary">Reward amount</p>
                            </div>
                            <strong>
                                {parseBalance(
                                    earned_reward,
                                    reward_token?.decimals,
                                )}{' '}
                                {reward_token?.symbol}
                            </strong>
                        </div>
                    )}
                    {display_withdraw_message && (
                        <div>
                            <Alert
                                className="mt-5"
                                title="Important information"
                                variant="note"
                            >
                                {withdraw_warning_messages[type]}
                            </Alert>
                        </div>
                    )}
                    {has_rebate && is_early_withdraw_active && (
                        <TextItem
                            title={'Early withdrawal fee'}
                            text={`${early_withdraw_penality}%`}
                        />
                    )}
                    {has_rebate && is_early_withdraw_active && (
                        <TextItem
                            title={'Early withdrawal fee amount'}
                            text={`${parseBalance(early_withdraw_fee_amount)} ${
                                input_token[0]?.symbol
                            }`}
                        />
                    )}
                    {has_rebate && is_early_withdraw_active && (
                        <TextItem
                            title={'Withdraw amount after fee'}
                            text={`${parseBalance(withdraw_amount_after_fee)} ${
                                input_token[0]?.symbol
                            }`}
                        />
                    )}
                    {has_rebate && is_early_withdraw_active && (
                        <TextItem
                            title={'Lock end date'}
                            text={lock_end_date}
                        />
                    )}
                    {has_rebate && is_early_withdraw_active && (
                        <Alert
                            className="mt-5"
                            title="Withdrawing before lock end date"
                            variant="note"
                        >
                            Anyone can withdraw at any time. <br />
                            However, withdrawing before the lock end date will
                            yield no rewards and incur an early withdrawal fee.
                        </Alert>
                    )}
                </div>
            )}
        </div>
    );
};
