import React from 'react';

import { Icon } from '@src/components/Staking/Icon';
import { setStakingModalOpen } from '@src/features/layout';
import { setModalState } from '@src/features/staking';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import { StakingModalTabs } from '@src/ts/constants';
import { effectiveApy, parseLockPeriod } from '@src/utils/staking';

export const ModalHeader: React.FC = () => {
    const dispatch = useAppDispatch();

    const { current_pool, pools } = useAppSelector((state) => state.staking);
    const pool = pools.find(({ id }) => id === current_pool);
    const lock_period = parseLockPeriod(pool);

    const { type, apr = '0' } = pool || {};

    const APR = ['compound', 'legacy-compound'].includes(type)
        ? (effectiveApy(parseFloat(apr) / 100, 365) * 100).toFixed(2) + '%'
        : pool?.apr;

    const is_liquidity = ['legacy-liquidity', 'liquidity'].includes(pool?.type);

    return (
        <div className="bg-surface-level-one sticky top-0 border-b border-border border-opacity-[0.03]">
            <div className="bg-gradient-alpha-right p-4">
                <button
                    data-testid="modal-header-btn"
                    onClick={() => {
                        dispatch(setModalState(StakingModalTabs.Deposit));
                        dispatch(setStakingModalOpen(false));
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>

                <div className="flex items-start justify-between mt-4">
                    <Icon pool={pool} />

                    {!is_liquidity && (
                        <div className="text-right">
                            <h3 className="text-3xl">
                                <strong>{APR}</strong>
                            </h3>
                            <p className="text-sm">{lock_period} days lock</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
