import { BigNumber } from '@ethersproject/bignumber';

/**
 * Calculate equivalent token amounts for liquidity provision.
 *
 * @param inputAmount - The input amount as a BigNumber (for token0 or token1).
 * @param isToken0Input - True if inputAmount is for token0, false for token1.
 * @param reserve0 - Reserve of token0 as a BigNumber.
 * @param reserve1 - Reserve of token1 as a BigNumber.
 * @param decimals0 - Decimals of token0 as a number.
 * @param decimals1 - Decimals of token1 as a number.
 * @returns An object containing token0Amount and token1Amount as BigNumbers.
 */
export function calculateEquivalentAmounts(
    inputAmount: BigNumber,
    isToken0Input: boolean,
    reserve0: BigNumber,
    reserve1: BigNumber,
    decimals0: number,
    decimals1: number,
): { token0Amount: BigNumber; token1Amount: BigNumber } {
    if (reserve0.isZero() || reserve1.isZero()) {
        throw new Error('Reserves cannot be zero.');
    }

    // Calculate scaling factors for token decimals
    const scale0 = BigNumber.from(10).pow(decimals0);
    const scale1 = BigNumber.from(10).pow(decimals1);

    if (isToken0Input) {
        // Normalize reserves to the same scale
        const normalizedReserve0 = reserve0.mul(scale1);
        const normalizedReserve1 = reserve1.mul(scale0);

        // Calculate equivalent token1 amount in normalized scale
        const token1Amount = inputAmount
            .mul(normalizedReserve1)
            .mul(BigNumber.from(10).pow(18))
            .div(normalizedReserve0)
            .div(scale0) // Adjust back to token1 scale
            .mul(scale1)
            .div(BigNumber.from(10).pow(18));

        return {
            token0Amount: inputAmount,
            token1Amount,
        };
    } else {
        // Normalize reserves to the same scale
        const normalizedReserve0 = reserve0.mul(scale1);
        const normalizedReserve1 = reserve1.mul(scale0);

        // Calculate equivalent token0 amount in normalized scale
        const token0Amount = inputAmount
            .mul(normalizedReserve0)
            .mul(BigNumber.from(10).pow(18))
            .div(normalizedReserve1)
            .div(scale1) // Adjust back to token0 scale
            .mul(scale0)
            .div(BigNumber.from(10).pow(18));

        return {
            token0Amount,
            token1Amount: inputAmount,
        };
    }
}
