import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { BigNumber } from '@ethersproject/bignumber';

import { isLegacyStakingType } from '@src/config';
import { staking_contract_versions } from '@src/constants';
import {
    setDepositAmount,
    setModalState,
    setToken1DepositAmount,
    updatePool,
} from '@src/features/staking';
import {
    useAppDispatch,
    useAppSelector,
    useDecubateContract,
} from '@src/hooks';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ContractType, StakingModalTabs } from '@src/ts/constants';
import { getTierOfUser } from '@src/utils/getters';
import { executeTx } from '@src/utils/web3';

import { getStakeArgs, getUnstakeArgs } from './util';

export const useStakingMethods = (): {
    loading: boolean;
    handleWithdraw: () => Promise<void>;
    handleDeposit: () => Promise<void>;
} => {
    const { _setUserTier, contract_manager } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const { user } = useAppSelector((state) => state.auth);

    const { account } = useWeb3Onboard();

    const {
        current_pool,
        pools,
        deposit_amount,
        token1_deposit_amount,
        withdraw_percent,
    } = useAppSelector((state) => state.staking);

    const pool = pools.find(({ id }) => id === current_pool);

    const { id, type, chain_id } = pool || {};

    const is_compound = ['compound', 'legacy-compound'].includes(type);

    const is_legacy = isLegacyStakingType(type);

    // contract instances
    const [staking] = useDecubateContract(
        staking_contract_versions[type],
        true,
        chain_id,
    );

    const [vault] = useDecubateContract(
        is_legacy ? ContractType.LegacyVault : ContractType.Vault,
        true,
        chain_id,
    );
    const contract = is_compound ? vault : staking;
    const [stake_method, stake_args] = getStakeArgs(
        pool,
        deposit_amount,
        token1_deposit_amount,
    );

    const [unstake_method, unstake_args] = getUnstakeArgs(
        pool,
        withdraw_percent,
    );

    const dispatch = useAppDispatch();

    const handleDeposit = async () => {
        setLoading(true);

        const params = stake_args.slice(0, stake_args.length - 1);
        const opts = stake_args[stake_args.length - 1] as Record<
            string,
            unknown
        >;

        try {
            const gasLimit = BigNumber.from(600_000);

            await executeTx(
                contract[stake_method](...params, {
                    ...opts,
                    gasLimit,
                }),
                'Transaction successfully complete',
            );

            dispatch(updatePool(id, type, account, contract_manager));
            dispatch(setDepositAmount('0'));
            dispatch(setToken1DepositAmount('0'));

            if (user?.wallet_address) {
                const userTier = await getTierOfUser();
                _setUserTier(userTier);
            }
        } catch (err) {
            toast.error(err.reason || err.message);
        }

        setLoading(false);
    };

    const handleWithdraw = async () => {
        setLoading(true);

        const params = unstake_args.slice(0, unstake_args.length - 1);
        const opts = unstake_args[unstake_args.length - 1] as Record<
            string,
            unknown
        >;

        try {
            const gasLimit = BigNumber.from(600_000);

            await executeTx(
                contract[unstake_method](...params, {
                    gasLimit,
                    ...opts,
                }),
                'Transaction successfully complete',
            );

            dispatch(updatePool(id, type, account, contract_manager));
            dispatch(setModalState(StakingModalTabs.Deposit));
            if (user?.wallet_address) {
                const userTier = await getTierOfUser();
                _setUserTier(userTier);
            }
        } catch (err) {
            toast.error(err.reason || err.message);
        }

        setLoading(false);
    };

    return { handleDeposit, handleWithdraw, loading };
};
