import { useEffect } from 'react';
import { commify, parseUnits } from '@ethersproject/units';

import { CurrencyInput } from '@src/components';
import {
    setDepositAmount,
    setToken1DepositAmount,
} from '@src/features/staking';
import { useAppDispatch, useAppSelector, useTokenBalance } from '@src/hooks';
import { PoolToken, Reserves } from '@src/ts/interfaces';
import { ChangeEvent } from '@src/ts/types';
import { parseBalance, removeCommas } from '@src/utils/web3';

import { parseNativeToken } from '../util';

import { calculateEquivalentAmounts } from './util';

export const Token1Input: React.FC<{
    token0_decimals: number;
    token1_decimals: number;
    is_native: boolean;
    token: PoolToken;
    reserves: Reserves;
    chain_id: number;
    disableInput: boolean;
}> = ({
    token0_decimals,
    token1_decimals,
    token,
    reserves,
    is_native,
    chain_id,
    disableInput,
}) => {
    const { loading, token1_deposit_amount } = useAppSelector(
        (state) => state.staking,
    );

    const dispatch = useAppDispatch();

    const deposit = parseUnits(
        removeCommas(token1_deposit_amount),
        token.decimals,
    );

    const setToken0Amount = (amount: string) => {
        const amount_token1 = parseUnits(removeCommas(amount), token1_decimals);
        const { token0Amount } = calculateEquivalentAmounts(
            amount_token1,
            false,
            reserves.reserve0,
            reserves.reserve1,
            token0_decimals,
            token1_decimals,
        );

        dispatch(
            setDepositAmount(parseBalance(token0Amount, token0_decimals, 6)),
        );
    };

    const handleChange = (e: ChangeEvent) => {
        let amount = parseFloat(removeCommas(e.target.value));
        amount = isNaN(amount) ? 0 : amount;
        dispatch(setToken1DepositAmount(commify(amount)));
        setToken0Amount(amount.toString());
    };

    const balance = useTokenBalance(token?.address, chain_id, is_native);

    const more_than_available = deposit.gt(balance);

    const handleMaxClick = () => {
        const parsed_balance = removeCommas(
            parseBalance(balance, token.decimals, 3),
        );

        dispatch(
            // eval wei balance will reduce amount if rounded up
            setToken1DepositAmount(parsed_balance),
        );
        setToken0Amount(parsed_balance);
    };

    const symbol = is_native ? parseNativeToken(token?.symbol) : token?.symbol;

    useEffect(() => {
        if (deposit.gt(0)) {
            setToken0Amount(token1_deposit_amount);
        }
    });

    return (
        <>
            <div className="w-full">
                <CurrencyInput
                    loading={loading}
                    valid={!more_than_available}
                    value={token1_deposit_amount}
                    onChange={(e) => handleChange(e)}
                    onMaxClick={handleMaxClick}
                    currency={symbol}
                    disabled={disableInput}
                />
            </div>
            <div className="flex items-start mt-4 text-sm">
                <div className="flex-1 text-sm text-secondary">
                    Available to stake
                </div>
                <strong
                    className={`${more_than_available ? 'text-accent' : ''}`}
                >
                    {`${parseBalance(balance, token.decimals, 3)} ${symbol}`}
                </strong>
            </div>
        </>
    );
};
